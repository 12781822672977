import React from 'react';
import { Link } from 'gatsby';
import Menu from '../components/NavBar/menu';
import SEO from '../components/seo';
import "../style/styles.css";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Menu />
    <div className="h-screen bg-black flex justify-center items-center flex-col">
    <h1 className="text-salmon text-5xl font-display">404</h1>
    <p  className="text-salmon font-alternates my-4">You just hit a route that doesn&#39;t exist.</p>
      <Link to='/'>
        <div className='mt-4 text-lg font-semibold font-display bg-white text-black rounded px-6 py-2'>
            BACK TO MAIN PAGE
        </div>
      </Link>
    </div>
  </>
);

export default NotFoundPage;
