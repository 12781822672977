import React, { Component } from 'react';
import { Link } from 'gatsby';
import withSizes from 'react-sizes';
import Done from './NavBarContainer';
import logo from '../../images/logotext.svg';

class Menu extends Component {
  render() {
    const { home, blog } = this.props;
    return (
      <div className="z-50 fixed left-0 top-0 h-16 flex flex-row bg-black py-2 text-white items-center justify-between w-screen overflow-x-hidden">
        <Done home={home} blog={blog} />
        <div className="hidden md:block lg:block xl:block">
        <NavBar home={home} blog={blog} />
        </div>
      </div>
    );
  }
}

const NavBar = home => {
  return (
    <div className="flex flex-row text-white items-center justify-between w-screen">
      <div className="ml-24">
        {!home.home && !home.blog ? (
          <div className="flex flex-row items-center">
            <Link to="/">
              <img
                className="h-4"
                alt="Midi converter for ios and android"
                src={logo}
              />
            </Link>
          </div>
        ) : (
          <div />
        )}
        {!home.home && home.blog ? (
          <div className="flex flex-row items-center">
            <Link to="/">
              <img
                className="h-4 "
                alt="Midi converter for ios and android"
                src={logo}
              />
            </Link>
            <div className="text-lg font-alternate mx-8">BLOG</div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="flex flex-row items-center mr-24">
        <Link to="/blog">
          <div className="text-lg font-semibold font-display mx-4">BLOG</div>
        </Link>
        <Link to="/about-us">
          <div className="text-lg text-white font-semibold font-display ml-4">
            ABOUT US
          </div>
        </Link>
      </div>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 700,
});

export default withSizes(mapSizesToProps)(Menu);
