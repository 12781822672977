import React, { Component, useState } from 'react';
import Navbar from './MobileNavbar';

const Done = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navbarSzOpen, setnavbarSzOpen] = useState(false);
  return (
    <div className="sm:block md:hidden lg:hidden xl:hidden w-full">
      <Navbar
        navbarState={navbarOpen}
        handleNavbar={() => setNavbarOpen(!navbarOpen)}
      />
    </div>
  );
};

export default Done;
