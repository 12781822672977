import React, { Component } from 'react';
import logo from '../../images/logotext.svg';
import { Link } from 'gatsby';
import { Spin as Hamburger } from 'hamburger-react';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  render() {
    return (
      <>
        <div className="bg-black flex flex-col w-screen">
          <div className="h-12 bg-black w-screen flex flex-row z-50 items-center justify-between px-8 py-8 -mt-2">
            <div className="flex flex-row items-center justify-center w-screen">
              <Link to="/">
                <img
                  className="h-4"
                  alt="Midi converter for ios and android"
                  src={logo}
                />
              </Link>
             {/* <div className='text-lg font-alternate mx-8'>
                BLOG
    </div>*/}
            </div>
            <Hamburger
              toggled={this.state.menu}
              toggle={() => this.setState({menu: !this.state.menu})}
            />
          </div>
          {this.state.menu ? (
            <div
              className="flex flex-col z-30 fixed top-0 right-0 w-full bg-black p-4 py-4 items-center" /*style={linkAnimation}*/
            >
              <div className="text-darkgrey text-xl font-semibold font-display my-4">
                MENU
              </div>
              <Link to="/">
                <div className="text-xl font-semibold font-display my-4">
                  MAIN PAGE
                </div>
              </Link>
              <Link to='/blog'>
                <div className='text-xl font-semibold font-display my-4'>
                  BLOG
                </div>
              </Link>
              <Link to='/about-us'>
                <div className='text-xl font-semibold font-display my-4'>
                  ABOUT US
                </div>
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
      </>
    );
  }
}

export default Navbar;
